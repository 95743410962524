import type { CleaveOptions } from 'cleave.js/options'

const numeralMask: CleaveOptions = {
  numeral: true,
  numeralThousandsGroupStyle: 'thousand',
}

const postiveOnlyNumeralMask: CleaveOptions = {
  ...numeralMask,
  numeralPositiveOnly: true,
}

const sortCodeMask: CleaveOptions = {
  delimiter: '-',
  blocks: [2, 2, 2],
  numericOnly: true,
}

const accountNumberMask: CleaveOptions = {
  delimiter: '',
  blocks: [1, 1, 1, 1, 1, 1, 1, 1],
  numericOnly: true,
}

export { numeralMask, sortCodeMask, postiveOnlyNumeralMask, accountNumberMask }
